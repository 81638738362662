import React, { useState, useEffect } from "react"
import _ from "lodash"
import styled from "styled-components"
import { Button, List } from "antd"
import { SoundFilled } from "@ant-design/icons"
import { Color, ENDPOINTS } from "../config"
import Layout from "../components/layout"
import { useColorsContext, useColorsDataLoading } from "../data/colorsContext"
import { ProjectItem } from "../models/models"

const IndexPage = () => {
  const { ctxHomeProjects } = useColorsContext()

  const [selectedProject, setselectedProject] = useState({} as ProjectItem)
  const [videoMuteState, setvideoMuteState] = useState(true as Boolean)

  useColorsDataLoading()

  useEffect(() => {
    if (_.isEmpty(selectedProject) && ctxHomeProjects !== undefined) {
      setselectedProject(ctxHomeProjects[0])
    }
  }, [ctxHomeProjects])

  return (
    <Layout title={"Home"} description={"home"} backgroundColor={Color.BLACK}>
      <StyledHeading>Home</StyledHeading>
      <VideoPlayerContainer>
        {selectedProject ? renderVideo() : ""}
      </VideoPlayerContainer>
      <MuteButton onClick={btnMuteClick}>
        <CustomSoundFilled ismuted={videoMuteState} />
      </MuteButton>
      {ctxHomeProjects ? (
        <ProjectsMenuContainer
          dataSource={ctxHomeProjects}
          // <ProjectItem onMouseOver={() => setselectedProject(item)} />
          //onMouseOver={() => {setselectedProject(item as ProjectItem)}}
          // tslint:disable-next-line:jsx-no-lambda
          renderItem={(item) => (
            <ProjectLine
              className={
                selectedProject !== undefined
                  ? selectedProject.Name == item.Name
                    ? "active"
                    : ""
                  : null
              }
              onMouseOver={() => {
                setselectedProject(item as ProjectItem)
                setvideoMuteState(true)
              }}
            >
              {item.Name}
            </ProjectLine>
          )}
        />
      ) : (
        <div />
      )}
    </Layout>
  )

  function btnMuteClick() {
    var video: HTMLVideoElement = document.getElementById(
      "colorsvideoplayer"
    ) as HTMLVideoElement
    video.muted = !videoMuteState
    setvideoMuteState(!videoMuteState)
  }

  function renderVideo() {
    var project = selectedProject //selectedProject

    if (project != null) {
      return (
        <VideoPlayer
          autoPlay
          playsInline
          preload="auto"
          muted
          loop
          id="colorsvideoplayer"
          key={project.BackgroundMedia ? project.BackgroundMedia : ""}
        >
          <source
            src={
              project.BackgroundMedia
                ? ENDPOINTS.ROOT + project.BackgroundMedia
                : ""
            }
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </VideoPlayer>
      )
    } else {
      return <div />
    }
  }
}

export default IndexPage

interface ButtonMuteProps {
  ismuted: Boolean
}

const ProjectsMenuContainer = styled(List)`
  float: left;
  margin-left: 25px !important;
  position: fixed !important;
  top: 50%;
`

const ProjectLine = styled(List.Item)`
  cursor: pointer;
  margin-left: 1px;
  padding: 6px 0 !important;
  color: ${Color.WHITE} !important;
  font-size: 12px;
  text-transform: uppercase;
  :hover {
    text-decoration: line-through;
  }
`

const VideoPlayerContainer = styled.div`
  position: relative;
  margin: 0 auto;
`

const VideoPlayer = styled.video`
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  top: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -99999999;
  /*pointer-events: none;*/
  @media (max-width: 768px) {
    height: 100%;
  }
`

const MuteButton = styled(Button)`
  position: fixed !important;
  right: 0;
  bottom: 0;
  margin-right: 20px;
  margin-bottom: 120px;
  background-color: rgb(0, 0, 0, 0.5) !important;
  color: ${Color.WHITE};
`
const CustomSoundFilled = styled(SoundFilled)<ButtonMuteProps>`
  color: ${(props) =>
    props.ismuted === true ? Color.GRAY : Color.WHITE}; !important;

`
const StyledHeading = styled.h1`
  color: #000;
  z-index: -999999999;
  position: absolute;
`
